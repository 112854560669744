// eslint-disable-next-line filenames/match-regex
import Layout from '../components/layout'
import { Link } from 'gatsby'
import React from 'react'
import SEO from '../components/seo'


const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <h1>Page Not Found</h1>
        <p>Sorry, the page you&#39;re looking for isn&#39;t here. <Link to="/">Click here</Link> to head back to our homepage.</p>
    </Layout>
)

export default NotFoundPage
